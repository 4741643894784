import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import { NgWhiteboardModule } from 'ng-whiteboard';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DemoComponent} from './demo/demo.component';
import {ScheduleComponent} from './schedule/schedule.component';
import {ContactComponent} from './contact/contact.component';
import {TestingComponent} from './testing/testing.component';
import { MultipleVideoCallComponent } from './multiple-video-call/multiple-video-call.component';
import { FullScreenChatComponent } from './full-screen-chat/full-screen-chat.component';


@NgModule({
    declarations: [
        AppComponent,
        DemoComponent,
        ScheduleComponent,
        ContactComponent,
        TestingComponent,
        MultipleVideoCallComponent,
        FullScreenChatComponent

    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgWhiteboardModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
