import { Component, OnInit, Sanitizer } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-full-screen',
  templateUrl: './full-screen-chat.component.html',
  styleUrls: ['./full-screen-chat.component.scss']
})
export class FullScreenChatComponent {

  name;
  url;

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      console.log(params);

      this.name = params.userid;

      console.log(this.name);

      this.url = this.sanitizer.bypassSecurityTrustResourceUrl('https://realpulsewebdemo.onrender.com/chat.html?userid=' + this.name + '&nickname=' + this.name);
    });
  }

}
